.faq_section h3,
.faq_section p {
    margin-top: 0
}

.faq_section p:last-child {
    margin-bottom: 0;
}

.faq_section {
    font-family: "Poppins", sans-serif;
    margin: 80px 0;
    overflow: hidden;
}

.faq_section .faq_section_inner {
    display: flex;
}

.faq_section .faq_section_inner .left_side_content,
.faq_section .faq_section_inner .right_side_content {
    width: 50%;
}

.faq_section .faq_section_inner .left_side_content {
    padding-right: 50px;
}

.faq_section .faq_section_inner .left_side_content .heading {
    font-size: 3rem;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 20px;
}

.faq_section .faq_section_inner .left_side_content p {
    font-size: 1rem;
    line-height: 1.4;
}

.faq_section .faq_section_inner .accordion_section .accordion_item {
    margin-bottom: 20px;
    overflow: hidden;
}

.faq_section .faq_section_inner .accordion_section .accordion_item .accordion_button {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(189 189 189 / 40%);
}

.faq_section .faq_section_inner .accordion_section .accordion_item .accordion_button .accordian_title {
    font-weight: 500;
    font-size: 22px;
    line-height: 1;
    width: calc(100% - 45px);

}

.faq_section .faq_section_inner .accordion_section .accordion_item .accordion_button {
    position: relative;
}

.faq_section .faq_section_inner .accordion_section .accordion_item .accordion_button .arrow_acordian svg {
    width: 25px;
    height: 25px;
    transition: position 0.8s ease-in-out;
    top: 0;
    position: relative;
}

.faq_section .faq_section_inner .accordion_section .accordion_item .accordion_button .arrow_acordian .minus_image {
    position: absolute;
    opacity: 0;
}

.faq_section .faq_section_inner .accordion_section .accordion_item.open .accordion_button .arrow_acordian .minus_image {
    position: relative;
    opacity: 1;
}

.faq_section .faq_section_inner .accordion_section .accordion_item.open .accordion_button .arrow_acordian .plus_image {
    position: absolute;
    opacity: 0;
}

.faq_section .faq_section_inner .accordion_section .accordion_item .accordion-content {
    /* transition: all 0.5s ease-in-out; */
    overflow: hidden;
    transition: max-height 0.5s ease-out;

}

.faq_section .faq_section_inner .accordion_section .accordion_item .accordion-content .accordian_content_inner {
    padding-top: 20px;
}


@media (max-width:1359px) {
    .faq_section {
        margin: 70px 0;
    }

    .faq_section .faq_section_inner .left_side_content {
        padding-right: 30px;
    }

    .faq_section .faq_section_inner .left_side_content .heading {
        font-size: 2.5rem;
        line-height: 1.1;
    }
}

@media (max-width:991px) {
    .faq_section {
        margin: 50px 0;
    }

    .faq_section .faq_section_inner {
        flex-direction: column;
    }

    .faq_section .faq_section_inner .left_side_content,
    .faq_section .faq_section_inner .right_side_content {
        width: 100%;
    }

    .faq_section .faq_section_inner .left_side_content {
        padding-right: 0;
        padding-bottom: 20px;
    }

    .faq_section .faq_section_inner .left_side_content .heading {
        font-size: 2rem;
        line-height: 1;
    }

    .faq_section .faq_section_inner .accordion_section .accordion_item .accordion_button .accordian_title {
        width: calc(100% - 38px);
    }

    .faq_section .faq_section_inner .accordion_section .accordion_item .accordion_button .arrow_acordian svg {
        width: 18px;
        height: 18px;
    }

    .faq_section .faq_section_inner .accordion_section .accordion_item .accordion_button .accordian_title {
        font-size: 20px;
    }
}
.advantages-section {
    padding: 50px 20px;
    background-color: #f8f8f8;
}

.advantages-section h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
}

.advantages-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.advantage-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.advantage-box:hover {
    transform: translateY(-10px);
}

.advantage-box .icon img {
    width: 100px;
    /* Adjust size as needed */
    height: auto;
    margin-bottom: 15px;
}

.advantage-box h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.advantage-box p {
    font-size: 1rem;
    color: #555;
}

@media (max-width: 1024px) {
    .advantages-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .advantages-grid {
        grid-template-columns: 1fr;
    }
}
/* Hero.css */
.hero {
    position: relative;
    overflow: hidden;
}

.video-container {
    position: relative;
    width: 100%;
    height: 600px;
    /* Full height */
    overflow: hidden;
}

.hero-video {
    object-fit: cover;
    /* Ensure the video covers the whole container */
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    /* Send the video behind other elements */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    z-index: 1;
    /* Overlay above the video */
}

.hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 2;
    /* Content above overlay */
}

.hero .logo {
    max-width: 200px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
}

.hero .logo:hover {
    transform: scale(1.05);
    /* Slight zoom on hover */
}

.hero h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    margin-top: 10px;
}

.hero p {
    font-size: 1.2rem;
    max-width: 741px;
    margin: 0 auto;
}

.hero .btn {
    margin-top: 20px;
    margin-bottom: 10px;
}

.hero .btn .contact {
    display: inline-block;
    color: #fff;
    background: #28b6ff;
    font-weight: 500;
    padding: 10px 40px;
    border-radius: 25px;
    border: 2px solid #28b6ff;
    transition: all .4s ease-in-out;
    text-decoration: none;
}
.hero .btn .contact:hover {
    background: transparent;
    border-color:  white;
    color: white;
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .hero .logo {
        max-width: 150px;
    }

    .hero h1 {
        font-size: 2rem;
    }

    .hero p {
        font-size: 1rem;
    }

    .hero-content {
        width: 80vw;
    }
}

@media (max-width: 480px) {
    .hero .logo {
        max-width: 120px;
    }

    .hero h1 {
        font-size: 1.8rem;
    }

    .hero p {
        font-size: 0.9rem;
    }
}
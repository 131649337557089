.heading_with_logo_items,
.heading_with_logo_items h2,
.heading_with_logo_items p {
    font-family: "Poppins", sans-serif;
    margin-top: 0;
}

.heading_with_logo_items p:last-child {
    margin-bottom: 0;
}

.heading_with_logo_items {
    margin: 60px 0;
}

.heading_with_logo_items .heading_with_logo_items_inner .heading_wrapper .heading {
    font-size: 4rem;
    line-height: 1.5;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.heading_with_logo_items .heading_with_logo_items_inner .heading_wrapper p {
    font-size: 1rem;
    line-height: 1.5;
}

.heading_with_logo_items .heading_with_logo_items_inner .logo_grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;
    margin-top: 50px;
}

.heading_with_logo_items .heading_with_logo_items_inner .logo_grid .logo_grid_item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    transition: all 0.5s ease-in-out;
}

.heading_with_logo_items .heading_with_logo_items_inner .logo_grid .logo_grid_item .img_wrapper {
    flex-shrink: 1;
}

.heading_with_logo_items .heading_with_logo_items_inner .logo_grid .logo_grid_item .img_wrapper img {
    width: 100%;
    height: 100%;
    filter: grayscale(1);
    transition: all 0.5s ease-in-out;
    max-width: 100%;
    max-height: 60px;
}


.heading_with_logo_items .heading_with_logo_items_inner .logo_grid .logo_grid_item:hover {
    transform: scale(1.1);

}

.heading_with_logo_items .heading_with_logo_items_inner .logo_grid .logo_grid_item:hover .img_wrapper img {
    filter: grayscale(0);
}


@media (max-width:1359px) {
    .heading_with_logo_items {
        margin: 50px 0;
    }

    .heading_with_logo_items .heading_with_logo_items_inner .heading_wrapper .heading {
        font-size: 3.5rem;
        line-height: 1.4;
    }

}

@media (max-width:1199px) {
    .heading_with_logo_items {
        margin: 40px 0;
    }

    .heading_with_logo_items .heading_with_logo_items_inner .heading_wrapper .heading {
        font-size: 3rem;
        line-height: 1.2;
    }


}

@media (max-width:991px) {
    .heading_with_logo_items .heading_with_logo_items_inner .logo_grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-top: 40px;

    }

    .heading_with_logo_items .heading_with_logo_items_inner .heading_wrapper .heading {
        font-size: 2.5rem;
        line-height: 1.1;
    }
}

@media (max-width:767px) {
    .heading_with_logo_items .heading_with_logo_items_inner .logo_grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width:575px) {
    .heading_with_logo_items .heading_with_logo_items_inner .logo_grid {
        margin-top: 30px;
        grid-template-columns: repeat(2, 1fr);
    }

    .heading_with_logo_items .heading_with_logo_items_inner .logo_grid .logo_grid_item .img_wrapper img {
        max-height: 45px;
    }
}
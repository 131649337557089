* {
    box-sizing: border-box
}


/* General Styles */



/* Contact Form Section */
.contact-form-section {
    padding: 50px 0;
    background: #f8f9fa;
}

.contact-form-section .contact-form-section_wrapper .heading_section_wrapper {
    margin-bottom: 50px;
}

.contact-form-section .contact-form-section_wrapper .heading_section_wrapper .heading {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 15px;
}

.contact-form-section .contact-form-section_wrapper .heading_section_wrapper .content {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4;
    text-align: left;
}


.contact-form-section .contact-form-section_wrapper .contact-form {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-items: center;
    gap: 15px 20px;
}


.contact-form-section .contact-form-section_wrapper .contact-form .form-group {
    width: 48.7%;
}

.contact-form-section .contact-form-section_wrapper .contact-form .form-group.input_textarea {
    width: 100%;
}

.contact-form-section .contact-form-section_wrapper .contact-form .form-group.submit-wrapper {
    width: 100%;
    text-align: center;
}

.contact-form-section .contact-form-section_wrapper .contact-form .form-group label {
    display: block;
    font-family: "Poppins", sans-serif;
    margin-bottom: 5px;
    font-weight: 700;
    text-transform: capitalize;
}

.contact-form-section .contact-form-section_wrapper .contact-form .form-group input,
.contact-form-section .contact-form-section_wrapper .contact-form .form-group textarea {
    font-family: "Poppins", sans-serif;
    width: 100%;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 12px;
    font-size: 1rem;
}

.contact-form-section .contact-form-section_wrapper .contact-form .form-group textarea {
    resize: vertical;
}

.contact-form-section .contact-form-section_wrapper .contact-form .submit-button {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    background: #007bff;
    color: white;
    border: 2px solid transparent;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.contact-form-section .contact-form-section_wrapper .contact-form .submit-button:hover {
    background: transparent;
    border-color: #007bff;
    color: black;
}

@media (max-width:991px) {
    .contact-form-section {
        padding: 30px 0;
    }

    .contact-form-section .contact-form-section_wrapper .contact-form .form-group {
        width: 100%;
    }
}

.error {
    color: red;
    font-size: 0.9em;
}

.success-message {
    color: green;
    margin-top: 10px;
    font-size: 1em;
}
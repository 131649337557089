footer {
    background: linear-gradient(135deg, #007bff, #6610f2);
    padding: 20px 0 10px;
    font-size: medium;
}

footer .footer_inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}


footer .footer_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

footer .footer_logo {
    max-width: 390px;
    height: auto;
    margin-top: 5px;
}

footer .footer_logo img {
    max-width: 150px;
    height: auto;
}

footer .footer_item_list ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

footer .footer_item_list .footer_item_list_wrapper .footer_menu_item {
    list-style: none;
    margin-right: 20px;
}

footer .footer_item_list .footer_item_list_wrapper li:last-child {
    margin-right: 0;
}

footer .footer_item_list .footer_item_list_wrapper .footer_menu_item a {
    color: white;
    font-family: "Poppins";
    font-weight: 500;
    font-style: normal;
    transition: all 0.5s ease-in-out;
    text-align: center;
    text-transform: capitalize;
    text-decoration: none;
}

footer .footer_item_list .footer_item_list_wrapper .footer_menu_item.active a,
footer .footer_item_list .footer_item_list_wrapper .footer_menu_item a:hover {
    color: #ffdd57;
    text-decoration: none;
}

footer .footer_content {
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

footer .footer_content p {
    margin: 0;
}

@media(max-width:575px) {
    footer .footer_wrapper {
        justify-content: center;
    }

    footer .footer_logo {
        width: 100%;
        max-width: 100%;
        text-align: center;
    }
}
.Left_right_image_and_content .Left_right_image_and_content_inner {
    padding: 100px 0;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper .images,
.Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container {
    width: 50%;
    position: relative;
    height: 600px;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper .images::before,
.Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(131 124 124 / 50%);
    z-index: 1;
    content: '';
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper.left .images,
.Left_right_image_and_content .Left_right_image_and_content_wrapper.left .video_container {
    order: 0;
    position: relative;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper.right .images,
.Left_right_image_and_content .Left_right_image_and_content_wrapper.right .video_container {
    order: 1;
    position: relative;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper .images img.logoimg,
.Left_right_image_and_content .Left_right_image_and_content_wrapper .images img.logoimghover,
.Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container img.logoimg,
.Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container img.logoimghover {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    transition: all 0.5s ease-in-out;
    width: 200px;
    height: 153px;
    opacity: 1;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper.left .images img.logoimg,
.Left_right_image_and_content .Left_right_image_and_content_wrapper.left .video_container img.logoimg {
    left: 10px;
    top: -30px;
    position: absolute;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    z-index: 2;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper.right .images img.logoimg,
.Left_right_image_and_content .Left_right_image_and_content_wrapper.right .video_container img.logoimg {
    right: 10px;
    top: -30px;
    position: absolute;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    z-index: 2;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper .images img.logoimghover,
.Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container img.logoimghover {
    left: 50%;
    top: 50%;
    position: absolute;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    z-index: 2;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper .images:hover img.logoimg,
.Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container:hover img.logoimg {
    opacity: 0;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper .images:hover img.logoimghover,
.Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container:hover img.logoimghover {
    transform: scale(1.1) translate(-50%, -50%);
    opacity: 1;
}

.Left_right_image_and_content .img {
    width: 100%;
    height: 100%;
}

.Left_right_image_and_content .video_content {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper.left .content {
    order: 1;
}

.Left_right_image_and_content .Left_right_image_and_content_wrapper.right .content {
    order: 0;
}

.Left_right_image_and_content .content {
    width: 50%;
    padding: 20px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Left_right_image_and_content .description {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.Left_right_image_and_content .btn {
    margin-top: 20px;
}

.Left_right_image_and_content .cash_study {
    color: black;
    text-decoration: none;
    display: inline-block;
    background: #7cc900;
    font-weight: 500;
    padding: 10px 40px;
    border-radius: 25px;
    border: 2px solid #7cc900;
    transition: all .4s ease-in-out;
}

.Left_right_image_and_content .cash_study:hover {
    color: #7cc900;
    background-color: transparent;
}

@media(max-width:1199px) {
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .images,
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container {
        height: 500px;
    }
}

@media(max-width:991px){
    .Left_right_image_and_content .Left_right_image_and_content_inner {
        padding: 80px 0;
    }
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .images,
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container {
        height: 326px;
    }

    .Left_right_image_and_content .Left_right_image_and_content_wrapper .images img.logoimg,
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .images img.logoimghover,
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container img.logoimg,
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container img.logoimghover {
        width: 150px;
        height: 118px;
    }
}

@media(max-width:575px) {
    .Left_right_image_and_content .Left_right_image_and_content_inner {
        padding: 50px 0;
    }
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .images,
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container {
        width: 100%;
        height: 250px;
    }

    /* .Left_right_image_and_content .Left_right_image_and_content_wrapper .images img.logoimg,
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .images img.logoimghover,
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container img.logoimg,
    .Left_right_image_and_content .Left_right_image_and_content_wrapper .video_container img.logoimghover {
        width: 100px;
        height: 100px;
    } */

    .Left_right_image_and_content .Left_right_image_and_content_wrapper.right .images,
    .Left_right_image_and_content .Left_right_image_and_content_wrapper.right .video_container {
        order: 0;
    }

    .Left_right_image_and_content .content {
        width: 100%;
    }

    .Left_right_image_and_content .Left_right_image_and_content_wrapper.right .content {
        order: 1;
    }
}
/* General Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
}

.landing-page {
  text-align: center;
  color: #333;
}

/* Header Styles */
.header {
  background: #fff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  max-width: 390px;
  /* Adjust the size of the logo */
  height: auto;
}

/* Container Styles */
.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}


/* Footer */
footer {
  background: #f8f9fa;
  padding: 20px 0;
  font-size: 0.9rem;
}


@media (max-width: 768px) {


  .service-list {
    flex-direction: column;
    align-items: center;
  }

  .service-item {
    max-width: 90%;
    margin-bottom: 20px;
  }
}

/* @media (max-width: 480px) {

  .logo {
    max-width: 100px;
  }
} */
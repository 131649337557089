.inner_page_banner p,
.inner_page_banner h2,
.inner_page_banner .btn {
    font-family: "Poppins", sans-serif;
    margin-top: 0;
    color: #fff;
}

.inner_page_banner {
    /* background-image: url('../assets/image.png'); */
    background-size: cover;
    background-position: center;
    padding: 60px 0;
    margin-top: 60px;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;
}

.inner_page_banner:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.6);
    pointer-events: none;
}

.inner_page_banner .inner_page_banner_inner {
    display: flex;
    min-height: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.inner_page_banner .inner_page_banner_inner h2 {
    font-size: 4rem;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.inner_page_banner .inner_page_banner_inner p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    max-width: 630px;
    margin-bottom: 20px;
}


.inner_page_banner .inner_page_banner_inner p:last-child {
    margin-bottom: 0;
}

@media (max-width:1439px) {
    .inner_page_banner .inner_page_banner_inner {
        min-height: 300px;
    }

}

@media (max-width:1359px) {

    .inner_page_banner .inner_page_banner_inner h2 {
        font-size: 3.5rem;
    }
}

@media (max-width:1199px) {
    .inner_page_banner .inner_page_banner_inner h2 {
        font-size: 3rem;
    }
    .inner_page_banner .inner_page_banner_inner {
        min-height: 250px;
    }
}

@media (max-width:991px) {
    .inner_page_banner {
        padding-bottom: 30px;
    }
    
    .inner_page_banner .inner_page_banner_inner {
        min-height: 200px;
    }

}

@media (max-width:767px) {
    .inner_page_banner .inner_page_banner_inner h2 {
        font-size: 2.5rem;
        margin-bottom: 15px;
    }
    
    .inner_page_banner .inner_page_banner_inner p {
        margin-bottom: 15px;
    }
}
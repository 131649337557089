.suitable_servieces_wrapper,
.suitable_servieces_wrapper h2,
.suitable_servieces_wrapper p,
.suitable_servieces_wrapper h6 {
    font-family: "Poppins", sans-serif;
    margin-top: 0;

}

.suitable_servieces_wrapper {
    background-color: #f9f9f9;
    padding: 50px 0;
}

.suitable_servieces_wrapper .suitable_servieces_wrapper_inner .heading {
    font-weight: 600;
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.1;
    margin-bottom: 30px;
}

.suitable_servieces_wrapper .suitable_servieces_wrapper_inner .suitable_servieces__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    text-align: center;
}

.suitable_servieces_wrapper .suitable_servieces_wrapper_inner .suitable_servieces__grid .suitable_servieces__grid_item {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.5s ease-in-out;
}

.suitable_servieces_wrapper .suitable_servieces_wrapper_inner .suitable_servieces__grid .suitable_servieces__grid_item:hover {
    transform: scale(1.1);
}

.suitable_servieces_wrapper .suitable_servieces_wrapper_inner .suitable_servieces__grid .suitable_servieces__grid_item img {
    width: 100px;
    height: 100px;
}

.suitable_servieces_wrapper .suitable_servieces_wrapper_inner .suitable_servieces__grid .suitable_servieces__grid_item .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 15px;
}

@media (max-width:1359px) {
    .suitable_servieces_wrapper .suitable_servieces_wrapper_inner .suitable_servieces__grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width:1199px) {}

@media (max-width:991px) {
    .suitable_servieces_wrapper {
        padding: 30px 0;
    }

    .suitable_servieces_wrapper .suitable_servieces_wrapper_inner .suitable_servieces__grid .suitable_servieces__grid_item .title {
        font-size: 16px;
    }
}

@media (max-width:767px) {
    .suitable_servieces_wrapper .suitable_servieces_wrapper_inner .suitable_servieces__grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width:575px) {
    .suitable_servieces_wrapper .suitable_servieces_wrapper_inner .suitable_servieces__grid {
        grid-template-columns: repeat(1, 1fr);
    }
}
.about-us-section p,
.about-us-section .stat h3,
.about-us-section .company-description h2 {
    font-family: "Poppins", sans-serif;
    margin-top: 0;
}



.about-us-section {
    padding: 60px 0;
    background-color: #f4f4f4;
}

.about-us-section .statistics {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    opacity: 0;
    /* Initially hidden */
    gap: 20px;
    transition: opacity 1s ease-in-out;
    /* Fade-in effect */
}

.about-us-section .statistics.visible {
    opacity: 1;
    /* Make visible when in view */
}

.about-us-section .stat {
    background: linear-gradient(135deg, #007bff, #6610f2);
    border-radius: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33%;
    padding: 20px 45px;
    text-align: center;
    row-gap: 12px;
}

.about-us-section .stat h3 {
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 0;
    color: white;
}


.about-us-section .company-description h2 {
    text-align: center;
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 20px;
}

.about-us-section .company-description p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
}

.about-us-section p:last-child {
    margin-bottom: 0;
}
.about-us-section .stat p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: white;
    margin-bottom: auto;
}

/* Responsive Design */
@media (max-width:1359px) {
    .about-us-section .stat {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width:991px) {
    .about-us-section .stat {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 768px) {
    .about-us-section .statistics {
        flex-direction: column;
        align-items: center;
    }

    .about-us-section .stat {
        width: 100%;
    }

    .about-us-section .stat h3 {
        font-size: 2.5rem;
    }
}



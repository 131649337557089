.what-we-are-doing-section h2,
.what-we-are-doing-section p,
.what-we-are-doing-section h3,
.what-we-are-doing-section ul li {
    font-family: "Poppins", sans-serif;
    margin-top: 0;

}

.what-we-are-doing-section {
    padding: 50px 0;
    background-color: #f4f4f4;
}

.what-we-are-doing-section h2 {
    font-size: 2rem;
    line-height: 1;
    font-weight: 700;
    color: #333;
    margin-bottom: 15px;
}

.what-we-are-doing-section p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    color: #666;
    margin-bottom: 15px;
}

.what-we-are-doing-section h3 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: #444;
    margin-bottom: 15px;
}

.what-we-are-doing-section p.important_text {
    color: #28b6ff;
    font-weight: 500;
    margin-bottom: 30px;
}



.what-we-are-doing-section ul {
    list-style-type: none;
    margin: 0 0 30px;
    padding: 0;
    gap: 20px;
}

.what-we-are-doing-section ul li {
    font-size: 1.1rem;
    line-height: 1;
    font-weight: 300;
    margin-bottom: 8px;
    padding-left: 30px;
    position: relative;
}

.what-we-are-doing-section ul li::after {
    content: '';
    position: absolute;
    left: 16px;
    top: 45%;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .what-we-are-doing-section h2 {
        font-size: 1.8rem;
    }

    .what-we-are-doing-section h3 {
        font-size: 1.3rem;
        line-height: 1.3rem;
    }

    .what-we-are-doing-section p.important_text,
    .what-we-are-doing-section ul {
        margin-bottom: 15px;
    }


}

@media (max-width: 768px) {
    .what-we-are-doing-section h2 {
        font-size: 1.6rem;
    }

    .what-we-are-doing-section ul li {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .what-we-are-doing-section h2 {
        font-size: 1.4rem;
    }

    .what-we-are-doing-section p,
    .what-we-are-doing-section h3 {
        font-size: 0.9rem;
    }

    .what-we-are-doing-section ul li {
        font-size: 0.9rem;
    }
}



.what-we-are-doing-section p:last-child {
    margin-bottom: 0;
}
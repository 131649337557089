.workflow-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
    /* Light background for better contrast */
}

.workflow-section h2 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    color: #333;
}

.workflow-steps {
    list-style: decimal inside;
    text-align: left;
    padding: 0;
    margin: 20px 0;
}

.workflow-step {
    margin-bottom: 15px;
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
}

.workflow-description {
    font-size: 1rem;
    color: #666;
    text-align: left;
    margin: 0;
}

@media(max-width:767px) {
    .workflow-section {
        padding: 20px 15px;
    }
}
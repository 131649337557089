/* Clients.css */
.clients-section p,
.clients-section h2,
.clients-section h3,
.clients-section .content ul li {
  font-family: "Poppins", sans-serif;
  margin-top: 0;
}

.clients-section p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #555;
  margin-bottom: 20px;
}

.clients-section p:last-child {
  margin-bottom: 0;
}

.clients-section {
  padding: 50px 0;
  background-color: white;
}

.clients-section h2 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 15px;
  margin-top: 0;
}

.clients-section .content p {
  color: #28b6ff;
  font-weight: 500;
}

.clients-section .content p.mb-big {
  margin-bottom: 30px;
}


.clients-section .content ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 0 0 30px;
}

.clients-section .content ul li {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1;
  color: black;
  position: relative;
  padding-left: 30px;
}

.clients-section .content ul li:after {
  content: '';
  position: absolute;
  left: 16px;
  top: 47%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: #333;
  border-radius: 50%;

}

.clients-section h3 {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 15px;
}

.clients-section .logo-slider {
  margin-top: 40px;
}

.client-logo {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

@media (max-width:1199px) {
  .clients-section {
    padding: 30px 0
  }


  .clients-section p,
  .clients-section .content p.mb-big ,
  .clients-section .content ul {
    margin-bottom: 15px;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .clients-section h2 {
    font-size: 2rem;
  }

  .clients-section h3 {
    font-size: 1rem;
  }

  .clients-section .content ul li {
    font-size: 0.9rem;
  }

  .clients-section .logo-slider {
    margin-top: 25px;
  }
}
/* Header base styles */

.header {
    padding: 20px 0;
    position: fixed;
    top: 0;
    width: 100%;
    background: linear-gradient(135deg, #007bff, #6610f2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.header .header_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.header .header_inner .logo,
.header .header_inner .logo img {
    width: 150px;

}

.header .header_inner .logo a {
    display: flex;
}

/* Navigation */
.header .header_inner .nav {
    display: flex;
    align-items: center;
    gap: 20px;
}

.header .header_inner .nav .menu_wrapper {
    display: flex;
    list-style: none;
    margin: 0;
    width: 100%;
    padding: 0;
    gap: 30px;
}

.header .header_inner .nav .menu_wrapper .menu_item {
    position: relative;
}

@media (min-width:768px) {
    .header .header_inner .nav .menu_wrapper .menu_item:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        background-color: #ffdd57;
        width: 0;
        height: 2px;
        transition: width 0.6s ease-in-out;
    }

    .header .header_inner .nav .menu_wrapper .menu_item:hover:after {
        width: 100%;
    }

    .header .header_inner .nav .menu_wrapper .menu_item.active:after {
        width: 100%;

    }
}

.header .header_inner .nav a {
    font-family: "Poppins", sans-serif;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    transition: all 0.5s ease-in-out;
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.header .header_inner .nav .menu_wrapper .menu_item.active a,
.header .header_inner .nav .menu_wrapper .menu_item:hover a {
    color: #ffdd57;
}

/* Hamburger menu styles */
.header .header_inner .hamburger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 25px;
    height: 20px;
}

.header .header_inner .hamburger .hamburger-line {
    width: 100%;
    height: 3px;
    background: white;
    transition: all 0.5s ease-in-out;
    border-radius: 2px;
    position: absolute;
    left: 0;
}


.header.open .header_inner .hamburger .hamburger-line {
    left: 10px;
}

.header .header_inner .hamburger:hover .hamburger-line {
    opacity: 0.7;
}

.header.open .header_inner .hamburger .hamburger-line.hamburger-line_three {
    opacity: 0;
}

.header .header_inner .hamburger .hamburger-line.hamburger-line_one {
    top: 3px;
}

.header .header_inner .hamburger .hamburger-line.hamburger-line_two {
    top: 8px;
}

.header .header_inner .hamburger .hamburger-line.hamburger-line_three {
    top: 13px;
}

.header.open .header_inner .hamburger .hamburger-line.hamburger-line_one {
    transform: rotate(45deg) translate(-1px, 9px);
}

.header.open .header_inner .hamburger .hamburger-line.hamburger-line_two {
    transform: rotate(135deg) translate(5px, 5px);
}

/* Mobile styles */




@media (max-width: 768px) {
    .header {
        background: #007bff;
    }

    .header .header_inner .nav {
        position: fixed;
        top: -150%;
        right: 0;
        background: linear-gradient(180deg, #007bff, #6610f2);
        width: 100vw;
        height: calc(100vh - 58px);
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px;
        transition: top 0.7s ease-in-out;
    }

    .header.open .header_inner .nav {
        top: 58px;

    }

    .header .header_inner .nav .menu_wrapper .menu_item {
        border-bottom: 1px solid white;
        padding-bottom: 10px;
    }

    .header .header_inner .nav .menu_wrapper {
        flex-direction: column;
        gap: 20px;
    }

    .header .header_inner .hamburger {
        display: flex;
    }
}